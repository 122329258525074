import React, {useState} from 'react';
import {navigate} from 'gatsby';
import {Helmet} from 'react-helmet';
import {withTranslation} from 'react-i18next';
import {GatsbyImage, getImage} from 'gatsby-plugin-image';
import {BgImage} from 'gbimage-bridge';
import {orderBy} from 'lodash';
import {useHouseholdPage} from '../hooks/queries';
import {
  Button,
  IconCard,
  MediaTile,
  MediaTileMobile,
  VideoLightBox,
} from '../components/common';
import {displayRichText} from '../utils';
import Layout from '../components/layout';
import '../styles/pages/household.scss';

const household = ({t}) => {
  const [modalIsOpen, setIsOpen] = useState(false);
  const data = useHouseholdPage();
  const {
    title,
    mainBanner,
    mainBannerTitle,
    mainBannerSubtitle,
    section1Title,
    section2Title,
    section2BodyText,
    videoTitle,
    videoThumbnail,
    videoUrl,
    section3Title,
    section3Description,
  } = data.contentfulHouseholdPage;

  const featuresAndBenefitsArray = orderBy(
    data.allContentfulFeatureAndBenefitsCard.nodes,
    ['order'],
    ['asc'],
  );

  const featureTilesArray = orderBy(
    data.allContentfulHouseholdFeaturesMediaTiles.nodes,
    ['order'],
    ['asc'],
  );

  const navigateToContact = () => {
    navigate('/contact', {state: {inquiry: 'household'}});
  };

  const headerBGImage = getImage(mainBanner.localFile);

  const renderFeaturesBenefits = () => {
    const cards = featuresAndBenefitsArray.map(obj => {
      const {title, description, icon, id} = obj;
      return (
        <IconCard
          id={id}
          icon={icon}
          title={title}
          description={description.raw}
          classes="icon-card w-full"
        />
      );
    });

    return cards;
  };

  const renderMediaTiles = () => {
    let type = 0;
    const tiles = featureTilesArray.map(obj => {
      if (type > 0) {
        type -= 1;
      } else {
        type += 1;
      }

      const {id, image, title, description} = obj;
      return (
        <div>
          <MediaTileMobile
            id={id}
            image={getImage(image.localFile)}
            altText={image.description}
            title={title}
            description={description.raw}
            classes="tablet:hidden"
          />
          <MediaTile
            type={type}
            id={id}
            image={getImage(image.localFile)}
            altText={image.description}
            title={title}
            description={description.raw}
            classes="mobile:hidden tablet:flex desktop:mb-4"
          />
        </div>
      );
    });

    return <div className="desktop:mb-20">{tiles}</div>;
  };

  const openModal = () => setIsOpen(true);

  const closeModal = () => setIsOpen(false);

  return (
    <Layout formType={2}>
      <Helmet>
        <meta charSet="utf-8" />
        <title>{title}</title>
      </Helmet>
      <div className="household">
        <BgImage image={headerBGImage} className="flex household__bg-image">
          <div className="flex w-full overlay absolute z-0 left-0 bottom-0 top-0" />
          <div className="flex flex-col w-full items-center justify-center z-10">
            <span className="text-center mx-4 text-3xl tablet:text-6xl heroImageText">
              {displayRichText(mainBannerTitle.raw)}
            </span>
            <span className="hero-subtitle mx-4 text-center text-lg tablet:text-xl desktop:text-2xl">
              {displayRichText(mainBannerSubtitle.raw)}
            </span>
            <Button
              handleClick={navigateToContact}
              classes="mt-6"
              label={t('household:buttons.secure')}
              useIcon={false}
            />
          </div>
        </BgImage>
        <div className="flex flex-col items-center light-section">
          <div className="household__section-one w-full flex flex-col items-center">
            <h2 className="section-text-household text-3xl tablet:text-6xl text-center mt-14 maxDesktop:mt-20 mb-8">
              {section1Title}
            </h2>
            <div className="household__section-one__benefits mb-14 desktop:mb-20 flex flex-wrap justify-center">
              {renderFeaturesBenefits()}
            </div>
          </div>
        </div>
        <div className="videoSection flex flex-col items-center">
          <span className="section-text-household text-4xl tablet:text-6xl text-center mt-14 maxDesktop:mt-20 mb-4">
            {displayRichText(section2Title.raw)}
          </span>
          <span className="section-text-household text-base tablet:text-lg desktop:text-xl desktop:max-w-3xl mb-8 maxDesktop:mb-12">
            {displayRichText(section2BodyText.raw)}
          </span>
          <div className="video-wrapper">
            <div
              onClick={openModal}
              onKeyDown={openModal}
              role="button"
              tabIndex={0}>
              <GatsbyImage
                image={getImage(videoThumbnail.localFile)}
                alt={videoThumbnail.description}
              />
            </div>
            <VideoLightBox
              isOpen={modalIsOpen}
              videoUrl={videoUrl}
              videoTitle={videoTitle}
              onClose={closeModal}
            />
          </div>
        </div>
        <div className="flex flex-col items-center light-section">
          <div className="flex flex-col items-center">
            <span className="section-text-household text-4xl tablet:text-6xl text-center mt-14 maxDesktop:mt-20 mb-4">
              {displayRichText(section3Title.raw)}
            </span>
            <span className="section-text-household text-base tablet:text-lg desktop:text-xl desktop:max-w-3xl mb-8 maxDesktop:mb-12">
              {displayRichText(section3Description.raw)}
            </span>
          </div>
          <div className="media-tile-section">{renderMediaTiles()}</div>
        </div>
      </div>
    </Layout>
  );
};

export default withTranslation()(household);
